@import 'niquel-loading';
@import "colors";

.niquel-main {
  width: 100%;
  margin-top: 3rem;
  min-height: calc(100vh - 3rem);
  background-color: $background-default;
}

.text-dark-gray {
  color: $dark-gray !important;
}

.text-light {
  opacity: 0.7;
}

.niquel-scroll {
  overflow: hidden;

  &:hover {
    overflow-y: overlay;
  }

  &::-webkit-scrollbar {
    width: 8px
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00)
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(190, 193, 197);
    border: 2px solid #262f3d;
    border-radius: 8px
  }
}

.niquel-fab-btn {
  position: fixed !important;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1202;
}

.background-error {
  background-color: $error-main !important;
}

.background-secondary-light {
  background-color: rgba(245, 177, 73, .1);
}

.background-secondary {
  background-color: $secondary-main;
  color: $white;
}

.hover-error {
  &:hover {
    background-color: rgba(211, 47, 47, 0.08) !important;
  }
}

.color-error {
  color: $error-main !important;
}

.MuiToolbar-regular {
  border-bottom: 0 !important;
}

.niquel-dialog-full-screen {
  //padding-left: 56px;
  z-index: 1202;
}

.niquel-date-input {
  width: 6rem;
}
