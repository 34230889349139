.articulo-detail-dialog {
  .item-img {
    min-width: 30rem;
    min-height: 10rem;

    .img-not-found {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ececec;

      svg {
        font-size: 3rem;
        color: #AAA;
      }
    }

    .img-found {
      margin: auto;

      img {
        max-height: 300px;
      }
    }
  }

  .column-cantidad {
    border-left: 1px solid #efefef;
  }
}