@import "../../../assets/styles/colors";

.niquel-header {
  .btn-back {
    color: white;
    opacity: .7;
    cursor: pointer;

    &:hover {
      transition: opacity 200ms;
      opacity: 1;
    }
  }

  .account-btn {
    &:hover {
      background-color: rgba(0, 0, 0, .08);
    }

    &, &:active {
      box-shadow: none;
    }

    .username {
      margin-left: 2px;
    }
  }

  .account-content {
    min-width: 15rem;
    padding: 0.5rem 1rem;
  }
}