.niquel-facturas-operation {
  .select {
    label {
      white-space: nowrap;
    }

    &.condComercial {
      min-width: 9.5rem;
    }

    &.listaPrecio {
      min-width: 5.5rem;
    }
  }

  .input {
    width: 8em;

    &.bonificacion {
      width: 4.2rem;
    }

    &.comision {
      width: 5.5rem;
    }
  }

  .date {
    width: 5.3rem;
  }
}